import React from "react";
import { ReactComponent as StarIcon } from "../../assets/logos/stars.svg";
import { Box, Typography } from "@mui/material";

function Testimonialbox({ rating, description, link, name, img }) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="testimonial-link">
      <Box className="testimonial-box">
        <Box>
          <Box className="rating">
            {[...Array(rating)].map((_, i) => (
              <span key={i} className="star">
                <StarIcon className="star-icon" />
              </span>
            ))}
          </Box>
          <Typography>{description}</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {img ? <img height={24} width={36} src={img} alt={img} />
            : ""}<Typography className="TestimonialboxTitleBot"> {name}</Typography>
        </Box>
      </Box>
    </a>
  );
}

export default Testimonialbox;
